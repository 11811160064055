import React, { useEffect, useState } from 'react';
import { Button, Empty, Table, Spin, Typography, Space, message } from 'antd';
import { EditOutlined, PlayCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BsFillCloudCheckFill } from 'react-icons/bs';
import { Link } from 'gatsby';
import AddVideos from "./AddVideos";
import EditContent from './EditContent';
import ContentFilter from './ContentFilter';
import moment from 'moment';
const { Text } = Typography;
const ContentDashboard = ({ uid, userDetails }) => {
    const [contentList, setContentList] = useState([]);
    const [openAddVideosDrawer, setOpenAddVideosDrawer] = useState(false);
    const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
    const [selectedContent, setSelectedContent] = useState(null);
    const [triggerRefresh, setTriggerRefresh] = useState(0);
    const fetchContent = (channel = null, dateRange = null) => {
        const requestData = { uid };
        if (channel) requestData.channel = channel;
        if (dateRange && dateRange.length === 2) {
            requestData.startDate = dateRange[0];
            requestData.endDate = dateRange[1];
        }

        axios({
            url: `${process.env.GATSBY_API_BASE_URL}/getContentVideos`,
            method: 'post',
            data: requestData,
        }).then(({ data }) => {
            setContentList(data);
        }).catch(error => {
            console.error('Failed to fetch content', error);
            message.error('Failed to load content');
        });
    };

    useEffect(() => {
        fetchContent();
    }, [triggerRefresh]);
    const showAddVideosDrawer = () => {

        if (userDetails.channelsAllow === 0) {
            message.error('Upgrade the subscription to add more channels and videos');
        } else {
            setOpenAddVideosDrawer(true);
        }
    };

    const closeAddVideosDrawer = () => {
        setOpenAddVideosDrawer(false);
    };

    const showEditDrawer = (record) => {
        setSelectedContent(record);
        setIsEditDrawerVisible(true);
    };

    const closeEditDrawer = () => {
        setIsEditDrawerVisible(false);
    };

    const contentColumns = [
        {
            title: 'Thumbnail',
            dataIndex: 'thumbnails',
            key: 'thumbnails',
            render: (obj) => (
                <img
                    src={obj.medium ? obj.medium.url : obj.default.url}
                    alt="hh"
                    height="60px"
                />
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'published',
            dataIndex: 'videoPublishedAt',
            key: 'videoPublishedAt',
            render: (text) => {
                return moment(text).format('MMM DD YYYY, h:mm:ss a');
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (type) => (type === 'done' ? <BsFillCloudCheckFill /> : <Spin size='small'/>),
        },
        {
            title: 'Actions',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<EditOutlined  style={{ fontSize: '16px' }} />}
                        onClick={() => {showEditDrawer(record)}}
                    />
                    <Button
                        type="link"
                        icon={<PlayCircleOutlined  style={{ fontSize: '16px' }} />}
                        ghost
                        onClick={() => record.url && window.open(record.url, '_blank')} // Open in a new tab
                        disabled={!record.url}
                    />
                </Space>
            ),
        },
    ];

    return (
        <div>
            {contentList.length > 0 ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 30,
                        }}
                    >
                        <Text
                            strong
                            style={{ margin: 0, fontSize: '1.5em' }}
                        >
                            Your Channel Content
                        </Text>
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                            <ContentFilter
                                uid={uid}
                                onFilterApply={(selectedChannel, selectedDateRange) => {
                                    const formattedDateRange = selectedDateRange ? selectedDateRange.map(date => {
                                        return date;
                                    }) : null;
                                    fetchContent(selectedChannel, formattedDateRange);
                                }}
                            />
                            <Button
                                style={{ fontSize: 14 }}
                                onClick={() => setTriggerRefresh(triggerRefresh + 1)}
                                icon={<ReloadOutlined/>}
                            >
                            </Button>
                            <Button
                                type="primary"
                                style={{ fontSize: 14 }}
                                onClick={showAddVideosDrawer}
                            >
                                Import videos
                            </Button>
                        </div>
                    </div>
                    <Table
                        dataSource={contentList}
                        columns={contentColumns}
                        size={50}
                        rowKey="id"
                    />
                    <EditContent
                        visible={isEditDrawerVisible}
                        onClose={closeEditDrawer}
                        contentData={selectedContent}
                    />
                </>
            ) : (
                <Empty
                    description="There is no video content imported, click on import button to add your channel videos."
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                >
                    <Button
                        type="primary"
                        size='large'
                        style={{ fontSize: 14 }}
                        onClick={showAddVideosDrawer}
                    >
                        Import videos
                    </Button>
                    &nbsp; &nbsp;
                    <Link to="/pricing">
                        <Button style={{ fontSize: 14 }} size='large'>Subscribe to a plan</Button>
                    </Link>
                </Empty>
            )}
            {
            <AddVideos
                uid={uid}
                open={openAddVideosDrawer}
                onClose={closeAddVideosDrawer}
                triggerRefresh={triggerRefresh}
                setTriggerRefresh={setTriggerRefresh}
                setOpen={setOpenAddVideosDrawer}
                contentList={contentList}
            />
        }
        </div>
    );
};

export default ContentDashboard;
